import RegisterForm from "@/components/common/auth/RegisterForm/RegisterForm"
import { useEffect, useState } from "react"
import styled from "styled-components"
import background from "@/public/header-background.svg"
import IntroForm from "@/components/common/auth/RegisterForm/introForm"

function RegisterWidget({ setRegisterModal }) {
  const [account, setAccount] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    loading: false,
    conditions: false,
    news: false,
    created: false,
    token: localStorage?.getItem("referral") || "",
    step: "intro",
  })

  return (
    <>
      {account.step === "intro" ? (
        <div className="ms-auto me-auto">
          <IntroForm
            account={account}
            setAccount={setAccount}
            setRegisterModal={setRegisterModal}
          />
        </div>
      ) : (
        <div className="ms-auto me-auto pt-3 pb-4">
          <RegisterForm account={account} setAccount={setAccount} />
        </div>
      )}
    </>
  )
}

export default RegisterWidget
